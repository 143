<template>
  <div
    class="g-box g-role-add-box page"
    :style="{
      backgroundColor: 'transparent',
      paddingTop: active !== 3 ? '32px' : '',
    }"
  >
    <div class="register-ruleForm" v-if="active === 0">
      <el-form
        v-loading="submitPopLoading"
        size="small"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <div class="myTitle">{{ $t("LiteFill.个人信息") }}</div>
        <div class="myRow">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item
                :label="$t('Login.userName') + '：'"
                prop="userName"
              >
                <el-input v-model="ruleForm.userName" maxlength="100"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('LiteFill.性别') + '：'" prop="sex">
                <el-radio v-model="ruleForm.sex" :label="1">{{
                  $t("LiteFill.男士")
                }}</el-radio>
                <el-radio v-model="ruleForm.sex" :label="2">{{
                  $t("LiteFill.女士")
                }}</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <!-- <el-form-item :label="$t('Login.postion') + '：'" prop="postion">
                <el-input v-model.trim="ruleForm.postion"></el-input>
              </el-form-item> -->
              <el-form-item
                :label="$t('Login.postion') + '：'"
                prop="positionId"
              >
                <el-select
                  style="width: 100%"
                  v-model.trim="ruleForm.positionId"
                  placeholder=""
                >
                  <el-option
                    v-for="item in positionList"
                    :key="item.id"
                    :label="item.chineseName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('Login.loginName') + '：'"
                prop="loginName"
              >
                <el-input
                  v-model.trim="ruleForm.loginName"
                  :placeholder="$t('LiteFill.邮箱可用于接收报表数据')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="myTitle">{{ $t("LiteFill.企业信息") }}</div>
        <div class="myRow">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item
                :label="$t('Login.regionId') + '：'"
                prop="regionId"
              >
                <el-select
                  style="width: 100%"
                  v-model.trim="ruleForm.regionId"
                  placeholder=""
                >
                  <el-option
                    v-for="item in regionList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('Login.companyName') + '：'"
                prop="companyName"
              >
                <el-input v-model="ruleForm.companyName" maxlength="100"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item
                :label="$t('Login.productInfo') + '：'"
                prop="productInfo"
              >
                <el-input
                  type="textarea"
                  maxlength="100"
                  v-model="ruleForm.productInfo"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('Login.yearOutputValue') + '：'"
                prop="yearOutputValue"
              >
                <el-input min="0" maxlength="15" v-model.trim="ruleForm.yearOutputValue">
                  <span slot="suffix">{{ $t("LiteFill.万元") }}</span>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="survey-box">
          <div class="survey-tit">{{ $t("Login.surveyTit") }}：</div>
          <div class="myRow">
            <el-checkbox-group v-model="checkList">
              <el-row :gutter="24">
              <el-col :span="12" style="margin-bottom: 16px" v-for="item in expectList" :key="item.id">
                <!-- <el-col :span="12" > -->
                <el-checkbox :label="item.id">
                  <span class="text-color-primary">{{ $t(`LiteFill['${item.name}']`) }}
                    {{ item.keyName ? "：" : "" }}</span>
                  <el-input
                    maxlength="100"
                    v-if="item.keyName"
                    v-model.trim="ruleForm[item.keyName]"
                  ></el-input></el-checkbox>
                <!-- </el-col> -->
              </el-col>
              </el-row>
            </el-checkbox-group>
          </div>
        </div>
      </el-form>
    </div>
    <div class="page-tit" :data-dark="active" v-if="active > 0">
      {{ $t("LiteFill.企业碳排放速算工具") }}
    </div>
    <div class="select-box" v-if="active === 1">
      <el-form :inline="true" :model="formInline" class="selectTime">
        <el-form-item :label="$t('LiteFill.年份') + ':'">
          <el-date-picker
            :clearable="false"
            v-model="formInline.year"
            type="year"
            @change="getData"
            :picker-options="pickerOptions"
            :placeholder="$t('global.请选择')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('Login.industryId') + ':'">
          <el-select v-model="formInline.industryId" @change="getData">
            <el-option
              v-for="item in industryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="select-content" v-if="deptList.length > 0">
        <el-tabs
          @tab-click="tabsClick"
          :tab-position="'left'"
          v-model="formInline.deptId"
        >
          <el-tab-pane
            v-for="(item, index) in deptList"
            :key="item.id"
            :name="item.id"
          >
            <el-badge
              slot="label"
              :hidden="getSelectNum(index) === 0"
              :value="getSelectNum(index)"
              :max="99"
            >
              <span> {{ item.name }}</span>
            </el-badge>
          </el-tab-pane>
        </el-tabs>
        <div class="select-emission" v-if="emissionList.length > 0">
          <div
            class="myCard"
            v-for="item in emissionList"
            :key="item.id"
            @click="selectEmission(formInline.deptId, item.id)"
            :class="{ isAct: item.isAct }"
          >
            <div class="icon"></div>
            <div class="name">{{ item.name }}</div>
            <div class="select"></div>
          </div>
        </div>
        <el-empty
          v-else
          style="flex: 1"
          :description="$t('MarkPrice.index.暂无数据')"
        ></el-empty>
      </div>
      <el-empty v-else :description="$t('MarkPrice.index.暂无数据')"></el-empty>
    </div>
    <div class="currentYear" v-if="active === 2">
      {{ $t("LiteFill.年份") }}: {{ $moment(formInline.year).format("YYYY") }}
    </div>
    <div class="currentYear" data-dark v-if="active === 3">
      {{ companyName }} <span style="padding-left:10px;"></span> {{ $moment(formInline.year).format("YYYY") }}
      {{ $t("LiteFill.年") }}
    </div>
    <div class="fill-table" v-if="active === 2">
      <el-table
        :data="[...tableData]"
        :span-method="objectSpanMethod"
        :height="524"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column
          align="center"
          prop="departmentName"
          :label="$t('LiteFill.部门')"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="emissionsName"
          :label="$t('LiteFill.排放源名称')"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="fillPrompt"
          :label="$t('LiteFill.填报项')"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="activityLevel"
          :label="$t('LiteFill.活动水平')"
        >
          <template slot-scope="{ row }">
            <el-input
              type="number"
              :maxlength="15"
              v-model.trim="row.activityLevel"
              :placeholder="$t('global.请输入')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="activityLevelName"
          :label="$t('LiteFill.单位')"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="report-form" v-if="active === 3">
      <div class="content">
        <div class="left">
          <el-table
            :data="threeTableData"
            :span-method="objectSpanMethodTwo"
            :height="500"
          >
            <el-table-column
              align="center"
              prop="deptName"
              :label="$t('list.部门')"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="emissionsName"
              :label="$t('list.排放源名称')"
            >
            </el-table-column>
            <el-table-column
              align="center"
              width="120"
              prop="emissionsEquivalent"
              :label="$t('list.温室气体排放量')"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="carbonReduction"
              :label="$t('list.减碳潜力预估')"
            >
              <template slot-scope="scope">
                <el-progress
                  :percentage="scope.row.carbonReduction || 0"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
          <div class="total-box">
            <div>
              {{ $t("LiteFill.年总排放量") }}:
              <span>{{ tableTotal.emissionTotal }}</span>
              {{ $t("LiteFill.tCO2e") }}
            </div>
            <div>
              {{ $t("LiteFill.年减排潜力") }}:
              <span>{{ tableTotal.zjplTotal }}</span> {{ $t("LiteFill.tCO2e") }}
            </div>
            <div>
              {{ $t("LiteFill.年排放成本") }}:
              <span>{{ tableTotal.pfpriceTotal }}</span> {{ $t("global.万元") }}
            </div>
            <div>
              {{ $t("LiteFill.年减排经济权益") }}:
              <span>{{ tableTotal.jfpriceTotal }}</span> {{ $t("global.万元") }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="barChart" ref="barRef"></div>
          <div class="pieChart" ref="pieRef"></div>
        </div>
      </div>
      <!-- <div class="tooltip">
        {{ tooltipInfo }}
        <div style="color: #1db0f2; font-size: 16px">
          <a
            href="http://www.carbonebook.com/index.php/zh/features"
            target="_blank"
          >
            {{ $t("LiteFill.标准版快速了解") }}
          </a>
        </div>
      </div> -->
      <div class="tooltip" v-html="tooltipInfo"></div>
      <div class="footer-btn-update" v-if="$store.state.user.userType == 3" @click="yyClick" style="margin-top:34px">{{ $t("LiteFill.预约升级到标准版") }}</div>
    </div>
    <div class="footer-btn-box" :class="{'third': active === 3}">
      <template v-if="active === 3">
        <div class="footer-btn-box-item1" @click="resetInput">
          {{ $t("LiteFill.重新计算") }}
        </div>
        <div class="footer-btn-box-item2" @click="exportFile">
          {{ $t("LiteFill.报告导出") }}
        </div>
      </template>
      <template v-if="active === 2">
        <div class="footer-btn-box-item1" @click="active--">
          {{ $t("LiteFill.上一步") }}
        </div>
        <div class="footer-btn-box-item2" @click="submitFill">
          {{ $t("global.submit") }}
        </div>
      </template>
      <template v-if="active === 1 || active === 0">
        <div
          class="footer-btn-box-item1"
          v-if="active !== 0 && isShowFill"
          @click="active--"
        >
          {{ $t("LiteFill.上一步") }}
        </div>
        <div class="footer-btn-box-item2" @click="nextStep()">
          {{ $t("LiteFill.下一步") }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import md5 from 'js-md5'
import tool from '@/tool'
export default {
  data () {
    return {
      // 公共
      active: null,
      formInline: {
        year: null,
        deptId: '',
        industryId: ''
      },
      // 第一步
      ruleForm: {
      },
      rules: {
        userName: [
          { required: true, message: this.$t('Login.userNameMsg'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        positionId: [
          { required: true, message: this.$t('Login.postionMsg'), trigger: 'blur' },
        ],
        regionId: [
          { required: true, message: this.$t('Login.regionIdMsg'), trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: this.$t('Login.companyNameMsg'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        productInfo: [
          { required: true, message: this.$t('Login.productInfoMsg'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        yearOutputValue: [
          { required: true, message: this.$t('Login.yearOutputValueMsg'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') },
          { pattern: /^(0|[1-9][0-9]*)(\.\d+)?$/, message: this.$t('global.floatMsg') }
        ],
        loginName: [
          { required: true, message: this.$t('Login.loginNameMsg'), trigger: 'blur' },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') },
          { pattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/, message: this.$t('global.emailErrorMsg'), trigger: 'blur' }
        ]
      },
      positionList: [],
      sexList: [{ name: '男士', id: 1 }, { name: '女士', id: 2 }],
      checkList: [],
      submitPopLoading: false,
      industryList: [],
      regionList: [],
      expectList: [],
      tempId: null,
      tempPhone: null,
      // 第二步
      pickerOptions: {
        disabledDate: (date) => new Date(date).getFullYear() < 1949 || new Date(date).getFullYear() > new Date().getFullYear(),
        cellClassName: (date) => {
          // return this.yearList.includes(new Date(date).getFullYear().toString()) ? 'year-fill-class' : ''
          // console.log(this.yearList, date)
          // console.log(this.yearList.includes(new Date(date).getFullYear().toString()))
        }
      },
      deptList: [],
      emissionList: [],
      logEmissionsList: [],
      // 第三步
      tableMatrix: [],
      autoTableHeight: 800,
      tableData: [],
      // 第四步
      threeTableData: [],
      tooltipInfo: '',
      tableTotal: {},
      threeTableMatrix: {},
      isShowFill: true,
      companyName: '',
      pieCart: {},
      barCart: {},
      pieInterval: null,
      exportDialog: false,

    }
  },
  watch: {
    active (val) {
      if (val === 0) {
        this.getUserInfo(false)
      }
    }
  },
  created () {
    // 期望值
    api.auth.queryIndustryList({
      type: 'expect'
    }).then(res => {
      this.expectList = res.data
    })
    // 区域
    api.auth.queryRegionTreeList({}).then(res => {
      this.regionList = res.data
    })
    // 职位
    api.lite.queryPositionList({}).then(res => {
      this.positionList = res.data
    })
    this.getIndustry()
    this.getUserInfo(true)
  },
  mounted () {
    // var a = document.querySelector('.u-body').getBoundingClientRect().height
    // this.autoTableHeight = a - 280
  },
  beforeDestory () {
    clearInterval(this.pieInterval)
  },
  methods: {
    yyClick() {
      this.$confirm("尊敬的用户，感谢您使用碳探Lite版，您的升级请求已经发送到系统后台，我们的系统顾问将会尽快和您联系", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.auth.upgradeFormalVersion({}).then((res) => {
            console.log(res);
            this.$message({
              showClose: true,
              message: '您的升级请求已经发送',
              type: "success",
            });
          });
        })
        .catch(() => {});
    },
    submitFill () {
      if (!this.tableData.every(item => item.activityLevel !== '')) return this.$message.error(this.$t('LiteFill.请完善活动水平'))
      if (!this.tableData.every(item => Number(item.activityLevel) >= 0)) return this.$message.error(this.$t('LiteFill.不可小于0'))
      if (this.tableData.some(item => Number(item.activityLevel) > 100000000000)) return this.$message.error(this.$t('LiteFill.数值过大'))
      this.$confirm(this.$t('LiteFill.submitMsg'), { type: 'warning' }).then(res => {
        this.nextStep()
      })
    },
    getUserInfo (flag) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: '#1A234AFF'
      })
      api.lite.checkRegUser().then(res => {
        this.tempId = res.data.id
        this.tempPhone = res.data.phone
        this.ruleForm = {
          ...this.ruleForm,
          ...res.data,
          sex: res.data.sex || 1
        }
        this.checkList = res.data.expect ? res.data.expect.split(',') : []
        // 只要填过了就去第三步
        if (this.$store.state.user.liteIsFirstLogin === 0) {
          this.isShowFill = false
        }
        // else if (res.data.loginName && flag) {
        //   this.active = 1
        // }
        if (!res.data.loginName) { // 无用户信息时, 直接跳第一步
          this.active = 0
          loading.close()
          return false
        }
        if (!flag) { // 判断返回上一步到0时 阻止下方代码运行
          loading.close()
          return false
        }
        api.lite.queryLiteEmissionsFinished().then(res => {
          if (flag && res.data.length > 0) {
            this.formInline.year = res.data[0].year
            this.active = 3
            this.initThree()
            loading.close()
          } else {
            this.active = 1
            loading.close()
          }
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {
        loading.close()
      })
    },
    async nextStep () {
      if (this.active === 0) {
        return this.submitForm('ruleForm', () => {
          this.active++
        })
      }
      if (this.active === 1) {
        if (!this.formInline.year || !this.formInline.industryId) return this.$message.error(this.$t('LiteFill.请选择年份和行业'))
        const { emissionsIds } = this.getSelectAll()
        if (emissionsIds.length < 1) return this.$message.error(this.$t('LiteFill.您还未选择排放源'))
        this.getFillData()
      }
      if (this.active === 2) {
        this.saveTableDataData(this.tableData, this.initThree)
      }
      this.active++
    },
    // 第二步
    getData () {
      if (!this.formInline.year || !this.formInline.industryId) return
      this.queryDeptList(() => {
        this.getLog()
      })
    },
    getLog () {
      this.logEmissionsList = []
      api.lite.queryAnnualLog({ year: this.$moment(this.formInline.year).format('YYYY'), inquiryId: this.formInline.industryId }).then(res => {
        if (res.data) {
          res.data.departmentIds.forEach(departmentId => {
            this.queryDeptEmissionList(departmentId)
          })
          this.logEmissionsList = res.data.emissionsList.map(item => item.id)
          res.data.emissionsList.forEach((item) => {
            this.selectEmissionOne(item.departmentId, item.id)
          })
          this.flagIsActive()
        }
      })
    },
    // 只选中不取消
    selectEmissionOne (deptId, currentId) {
      const deptList = [...this.deptList]
      const currentDeptIndex = deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1) {
        if (deptList[currentDeptIndex].emissionId && !deptList[currentDeptIndex].emissionId.includes(currentId)) {
          deptList[currentDeptIndex].emissionId.push(currentId)
        } else {
          deptList[currentDeptIndex].emissionId = [currentId]
        }
      }
      this.deptList = deptList
    },
    // 行业
    getIndustry () {
      api.lite.queryIndustryList().then(res => {
        this.industryList = res.data
      })
    },
    // 部门
    queryDeptList (callback = () => { }) {
      api.lite.queryDeptList({ industryId: this.formInline.industryId }).then(res => {
        this.deptList = res.data
        if (this.deptList.length > 0) {
          this.formInline.deptId = this.deptList[0].id
          this.queryEmissionList(this.deptList[0].id)
        }
        callback()
      })
    },
    tabsClick ({ name }) {
      this.queryEmissionList(name)
    },
    // 不切换tab,隐式查询部门下的排放源
    queryDeptEmissionList (deptId) {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex === -1) return
      api.lite.deptQueryEmission({ departmetIds: [deptId], industryId: this.formInline.industryId }).then(res => {
        this.deptList[currentDeptIndex].data = res.data.map(item => ({ ...item, isAct: this.logEmissionsList.includes(item.id) }))
      })
    },
    // 根据部门和行业id查排放源
    queryEmissionList (deptId) {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1 && this.deptList[currentDeptIndex].data) {
        return this.getEmissionList(deptId)
      }
      api.lite.deptQueryEmission({ departmetIds: [deptId], industryId: this.formInline.industryId }).then(res => {
        if (currentDeptIndex !== -1) {
          this.deptList[currentDeptIndex].data = res.data.map(item => ({ ...item, isAct: this.logEmissionsList.includes(item.id) }))
          this.getEmissionList(deptId)
        }
      })
    },
    // 显示当前部门的排放源列表
    getEmissionList (deptId) {
      this.emissionList = []
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1) {
        this.emissionList = this.deptList[currentDeptIndex].data
      } else {
        this.emissionList = []
      }
    },
    // 判断排放源是否选中
    flagIsActive () {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === this.formInline.deptId)
      if (currentDeptIndex === -1 || !this.deptList[currentDeptIndex].emissionId) return false
      const currentDept = this.deptList[currentDeptIndex]
      const resList = currentDept.data.map(item => ({ ...item, isAct: currentDept.emissionId.includes(item.id) }))
      this.deptList[currentDeptIndex].data = resList
      this.getEmissionList(this.formInline.deptId)
    },
    // 选中和取消选中
    selectEmission (deptId, currentId) {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1) {
        if (this.deptList[currentDeptIndex].emissionId) {
          const currentSelectList = this.deptList[currentDeptIndex].emissionId
          if (currentSelectList.includes(currentId)) {
            const temp = currentSelectList.findIndex(item => item === currentId)
            this.deptList[currentDeptIndex].emissionId.splice(temp, 1)
          } else {
            this.deptList[currentDeptIndex].emissionId.push(currentId)
          }
        } else {
          this.deptList[currentDeptIndex].emissionId = [currentId]
        }
      }
      this.flagIsActive(currentId)
    },
    getSelectNum (index) {
      const item = this.deptList[index]
      if (!item.emissionId) return 0
      return item.emissionId.length
    },
    // 第三步
    getSelectAll () {
      const departmetIds = []
      const emissionsIds = this.deptList.map(item => {
        if (item.emissionId && item.emissionId.length > 0) {
          departmetIds.push(item.id)
          return item.emissionId
        } else {
          return []
        }
      })
      return {
        emissionsIds: emissionsIds.flat(Infinity),
        departmetIds
      }
    },
    getFillData () {
      const { emissionsIds, departmetIds } = this.getSelectAll()
      api.lite.queryTableData({
        emissionsIds: [...new Set(emissionsIds)],
        departmetIds,
        year: this.$moment(this.formInline.year).format('YYYY')
      }).then(res => {
        const matrixIndex = { // 手动定位
          departmentName: 0
        }
        this.tableData = res.data.map(o=>{
          return {
            ...o,
            activityLevel: o.activityLevel >= 0 ? o.activityLevel + '' : ''
          }
        })
        this.tableMatrix = tool.tableMatrix.matrix(matrixIndex, this.tableData)
      })
    },
    saveTableDataData (data, callback = () => { }) {
      api.lite.saveTableData({ list: data, industryId: this.formInline.industryId }).then(res => {
        callback()
      })
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (this.tableMatrix[columnIndex]) {
        return this.tableMatrix[columnIndex][rowIndex]
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    objectSpanMethodTwo ({ row, column, rowIndex, columnIndex }) {
      if (this.threeTableMatrix[columnIndex]) {
        return this.threeTableMatrix[columnIndex][rowIndex]
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    // 第四步
    initThree () {
      api.lite.queryThreeData({ industryId: this.formInline.industryId, year: this.formInline.year ? this.$moment(this.formInline.year).format('YYYY') : '' }).then(res => {
        const { departTableMap, rangeMap, departMap, info, companyName } = res.data
        // console.log(res.data)
        this.companyName = companyName
        this.tooltipInfo = info
        this.tableTotal = departTableMap
        const temp = departTableMap.departTableMapList.map(item => {
          return item.emissionsTableMapList.map(node => ({ ...node, deptName: item.deptName }))
        }).flat(Infinity)
        this.threeTableMatrix = tool.tableMatrix.matrix({ deptName: 0 }, temp)
        this.threeTableData = temp
        this.$nextTick(() => {
          this.initBar(rangeMap.rangeMapList)
          this.initPie(departMap.departMapList)
        })
      })
    },
    resetInput () {
      this.active = 1
      this.formInline.year = null
      this.formInline.industryId = null
      this.deptList = []
      this.emissionList = []
    },
    canvasToBase64 (target) {
      // const canvas = target.querySelector('canvas')
      // return canvas.toDataURL("image/jpeg", 1).replace('data:image/jpeg;base64,', '')
      return target.getDataURL().replace('data:image/png;base64,', '')
    },
    initBar (data) {
      const myCart = this.echarts.init(this.$refs.barRef)
      myCart.setOption(
        {
          title: {
            text: this.$t('LiteFill.各范围排放量'),
            top: 16,
            left: 24,
            textStyle: {
              color: '#fff'
            }
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: data.map(item => item.scopeName),
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#dfe1e0'
              }
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#c8c8c8'
              }
            }
          },
          series: [
            {
              data: data.map((item, index) => ({
                value: item.total,
                itemStyle: {
                  color: {
                    type: 'linear', // 线性渐变
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0,
                      color: ['#73DEDEFF', '#FCCE01FF', '#FB861DFF'][index] // 0%处的颜色为红色
                    }, {
                      offset: 1,
                      color: ['#006EFEFF', '#FC9607FF', '#EB491FFF'][index]
                    }]
                  }
                }
              })),
              barWidth: 50,
              type: 'bar',
              label: {
                show: true,
                position: 'top',
                color: '#fff'
              }
            }
          ]
        }
      )
      this.barCart = myCart
    },
    initPie (data) {
      const myCart = this.echarts.init(this.$refs.pieRef)
      myCart.setOption({
        title: {
          text: `${this.$t('LiteFill.部门排放分布')}（${this.$t('LiteFill.tCO2e')}）`,
          top: 16,
          left: 24,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          containLabel: true
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: '100',
          right: '60',
          icon: 'circle',
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['40%', '60%'],
            color: [
              '#73dede',
              '#ffae26',
              '#ff7151',
              '#3770E2',
              '#C037E2',
              '#71E75F',
              '#32C88A',
              '#E1DC5E',
              '#FFAE26',
              '#7968E3',
              '#FE6F50',
              '#71DCDC',
              '#E75D5D'
            ],
            data: data.map(item => ({ name: item.deptName + ' ' + (this.tableTotal.emissionTotal ? (item.total / this.tableTotal.emissionTotal * 100).toFixed(2) + '% ' : ''), value: item.total })),
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80
            },
            label: {
              formatter: this.$t('LiteFill.排放量') + ':  {c} ' + '\n' + this.$t('LiteFill.占比') + ':  {d}%',
              color: '#666666',
              show: false,
              minMargin: 5,
              lineHeight: 15
            },
          }
        ]
      })
      this.pieCart = myCart
      let index = -1
      if (this.pieInterval) { clearInterval(this.pieInterval) }
      this.pieInterval = setInterval(() => {
        if (index === data.length - 1) {
          index = -1
        }
        index++
        if (!this.exportDialog) {
          myCart.dispatchAction({
            type: 'showTip',
            // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
            seriesIndex: 0,
            dataIndex: index
          })
          myCart.dispatchAction({
            type: 'highlight',
            // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
            seriesIndex: 0,
            dataIndex: index
          })
          const downIndex = index === 0 ? data.length - 1 : index - 1
          myCart.dispatchAction({
            type: 'downplay',
            // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
            seriesIndex: 0,
            dataIndex: downIndex
          })
        } else {
          myCart.dispatchAction({
            type: 'hideTip',
            // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
            seriesIndex: 0,
          })
          myCart.dispatchAction({
            type: 'downplay',
            // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
            seriesIndex: 0,
          })
        }
      }, 2000)
    },
    exportFile () {
      this.exportDialog = true
      this.pieCart.dispatchAction({
        type: 'hideTip',
        // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
        seriesIndex: 0,
      })
      this.pieCart.dispatchAction({
        type: 'downplay',
        // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
        seriesIndex: 0,
      })
      this.$prompt(this.$t('LiteFill.报表将通过邮箱发送给您'), {
        inputPattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/,
        inputErrorMessage: this.$t('global.emailErrorMsg'),
        inputValue: this.ruleForm.loginName,
        closeOnPressEscape: false,
        closeOnClickModal: false
      }).then(({ value }) => {
        this.barCart.setOption(
          {
            title: {
              textStyle: {
                color: '#000'
              }
            },
            xAxis: {
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#000'
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#000'
                }
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#000'
                }
              }
            },
            series: [
              {
                label: {
                  show: true,
                  position: 'top',
                  color: '#000'
                }
              }
            ]
          }
        )
        this.pieCart.setOption(
          {
            title: {
              textStyle: {
                color: '#000'
              }
            },
            legend: {
              textStyle: {
                color: '#000'
              }
            },
          }
        )
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.8) !important'
        })
        api.lite.exportFile({
          year: this.$moment(this.formInline.year).format('YYYY'),
          email: value,
          pirImage: this.canvasToBase64(this.pieCart),
          barImage: this.canvasToBase64(this.barCart)
        }).then(res => {
          this.barCart.setOption(
            {
              title: {
                textStyle: {
                  color: '#fff'
                }
              },
              xAxis: {
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: '#dfe1e0'
                  }
                }
              },
              yAxis: {
                type: 'value',
                splitLine: {
                  lineStyle: {
                    type: 'dashed'
                  }
                },
                axisTick: {
                  show: false
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: '#c8c8c8'
                  }
                }
              },
              series: [
                {
                  label: {
                    show: true,
                    position: 'top',
                    color: '#fff'
                  }
                }
              ]
            }
          )
          this.pieCart.setOption(
            {
              title: {
                textStyle: {
                  color: '#fff'
                }
              },
              legend: {
                textStyle: {
                  color: '#fff'
                }
              },
            }
          )
          loading.close();
          this.$message.success(this.$t('global.handleOk'))
        })
      }).catch(err => {
        this.exportDialog = false
      })
    },
    // 第一步
    submitForm (formName, callback = () => { }) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checkList.length < 1) return this.$message.error('请选择企业碳管理期望')
          this.submitPopLoading = true
          api.lite.updateLiteUser({
            ...this.ruleForm,
            userName: this.ruleForm.userName.trim(),
            id: this.tempId,
            productInfo: this.ruleForm.productInfo.trim(),
            phone: this.tempPhone,
            expect: this.checkList.length > 0 ? this.checkList.join() + ',' : ''
          }).then((res) => {
            this.submitPopLoading = false
            callback && callback()
          }).catch((err) => {
            this.submitPopLoading = false
          })
        }
      })
    }
  }
}
</script>

<style>
/* .u-body {
  background-color: #f7f8f7;
} */
</style>
<style lang="scss" scoped>
 @import "~assets/css/global-variable.scss";

* {
  box-sizing: border-box;
}
/deep/.el-progress{
  text-align: left!important;
  display: flex;
  align-items: center;
}
/deep/.el-progress__text {
  color: $text-color-primary;
  flex: 0 0 55px;
}
/deep/.el-progress-bar__inner {
  background: linear-gradient(270deg, #13cdcd 0%, #006dff 100%);
}
// /deep/.el-progress-bar{
//   width: 180px;
// }
.page {
  width: 1440px;
  position: relative;
  background-color: transparent;
  min-height: calc(100% - 64px);
  padding: 32px;
  padding-bottom: 100px;
  margin: 0 auto;
  padding-top: 0;
  .page-tit {
    text-align: center;
    padding: 28px 0;
    margin: -32px -32px 32px;
    font-size: 20px;
    background: transparent;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: $text-color-primary;
    &[data-dark="3"] {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .currentYear {
    font-size: 14px;
    color: #fff;
    margin-bottom: 24px;
  }
  .select-box {
    /deep/ .select-content {
      display: flex;
      height: 500px;
      .el-tabs {
        height: 100%;
        flex-shrink: 0;
        .el-tabs__content {
          display: none;
        }
        .el-badge__content {
          height: 14px;
          line-height: 14px;
        }
        .el-tabs__nav-scroll {
          padding-top: 20px;
        }
        .el-tabs__active-bar {
          width: 4px;
          border-radius: 2px 2px 2px 2px;
        }
        .el-tabs__item {
          font-size: 16px;
          transition: all 0.2s;
          padding-right: 30px;
          &.is-active {
            /* font-size: 20px; */
            transform: scale(1.2);
          }
        }
      }
      .select-emission {
        flex: 1;
        max-height: 100%;
        height: max-content;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        .myCard {
          margin-left: 24px;
          margin-bottom: 24px;
          position: relative;
          width: 217px;
          height: 85px;
          background: transparent;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid $panel-border-primary;
          cursor: pointer;
          transition: transform 0.3s;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: $fc-theme;
          }
          &.isAct,
          &:hover {
            border: 1px solid $fc-theme;
            background: $panel-bg-primary;
          }
          .icon {
            width: 48px;
            height: 48px;
            background-color: red;
            margin-right: 21px;
            background: url("./../../assets/img/lite/emission-icon.png")
              no-repeat center/95% 100%;
            flex-shrink: 0;
          }
          .name {
            flex: 1;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: $text-color-primary;
            user-select: none;
          }
          .select {
            width: 22px;
            height: 22px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
          }
          &.isAct {
            .select {
              background: url("./../../assets/img/lite/active-icon.png")
                no-repeat center/cover !important;
            }
          }
        }
      }
    }
  }
  /deep/ .selectTime {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner,
    .el-form-item__content {
      width: 118px;
    }
    .el-form-item + .el-form-item {
      margin-left: 32px;
    }
  }
}
.report-form {
  .tooltip {
    margin-top: 8px;
    font-size: 14px;
    font-family: PingFangSC-Medium-, PingFangSC-Medium;
    color: #13cccdff;
    line-height: 22px;
  }
  .content {
    display: flex;
    .left {
      position: relative;
      padding: 33px;
      margin-right: 22px;
      width: 848px;
      height: 600px;
      background: transparent;
      border-radius: 4px 4px 4px 4px;
      .total-box {
        position: absolute;
        bottom: 20px;
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: $text-color-primary;
        & > div {
          margin-top: 5px;
          &:not(:last-child) {
            margin-right: 33px;
          }
          // span {
          //   font-weight: 600;
          //   margin: 0 5px;
          //   color: $fc-theme;
          // }
        }
      }
    }
    .right {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      color: #fff;
      justify-content: space-between;
      & > div {
        height: 50%;
        background-color: transparent;
      }
      .barChart {
        margin-bottom: 20px;
      }
      /*
      .pieChart {
        height: 330px;
      } */
    }
  }
}

/deep/.register-ruleForm {
  width: 1000px;
  margin: 0 auto;
  .myRow {
    padding-left: 57px;
  }
}
/deep/.survey-box {
  .el-row {
    margin-bottom: 24px;
    .el-col {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
  .survey-tit {
    margin-top: 38px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    color: $text-color-white;
    &::before {
      content: "*";
      display: inline-block;
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .el-input {
    width: 186px;
    .el-input__inner {
      font-size: 14px;
      height: auto;
      border: none;
      border-bottom: 1px solid #dcdcdc;
    }
  }
}
.myTitle {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: bold;
  color: $text-color-white;
  margin-bottom: 24px;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 8px;
    width: 4px;
    height: 22px;
    background: $fc-theme;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }
}
.footer-btn-update{
    width: 136px;
    height: 32px;
    background: #12cace;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    float: left;
}
.footer-btn-box {
  position: absolute;
  left: 50%;
  bottom: 30px;
  display: flex;
  transform: translateX(-50%);
  &.third{
    bottom: 100px;
  }
  .reset-btn {
    width: 100px !important;
  }
    &-item1 {
    width: 88px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    opacity: 0.65;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ffffff;
  }
  &-item2 {
    width: 88px;
    height: 32px;
    margin-left: 24px;
    border-radius: 3px 3px 3px 3px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    background: #006dffff;
  }
  .el-button {
    width: 128px;
  }
}
canvas {
  background-color: #fff;
}
</style>
